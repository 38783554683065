/*****
  compile_report.js
  -----------------
  The functions on this page are for the functionality of the 'Compile your own report' ('Stel uw jaarverslag zelf samen') section.

  There are 5 sections:

    1. Functions used for ticking or unticking the level-2 <input>s when the level-1 input is ticked or unticked.

    2. Functions used for ticking or unticking the level-1 <input> depending on whether all of level-2 <input>s are ticked.

    3. Functions used for counting the selection and clearing the selection.

    4. Functions for when the 'ADD TO PDF' button is clicked.

    5. Creation of cookies to remember the data between pages.

    6. PDF generation from the selected nodes.

*****/
/*
// *** 1. ***
// Returns the child <li> <input> elements by traversing the DOM tree.
function getChildInputs(checkbox) {
  var ulElement = document.getElementById(checkbox).getElementsByTagName("ul");
  //var liElements = ulElement[0].getElementsByTagName("li");
  var liElements = [];
  if (ulElement[0] != undefined) {
    liElements = ulElement[0].getElementsByTagName("li");
  }
  var inputElements = [];

  for (var i = 0; i < liElements.length; i++) {
    inputElements.push(liElements[i].getElementsByTagName("input")[0]);
  }

  return inputElements;
}

// Loops through the childInputs array and sets their 'checked' value to true.
function tickChildInputs(checkbox) {
  var childInputs = getChildInputs(checkbox);

  for (var i = 0; i < childInputs.length; i++) {
    childInputs[i].checked = true;
  }
}

// Loops through the childInputs array and sets their 'checked' value to false.
function untickChildInputs(checkbox) {
  var childInputs = getChildInputs(checkbox);

  for (var i = 0; i < childInputs.length; i++) {
    childInputs[i].checked = false;
  }
}

// Uses the above three functions. Determines whether to tick or untick the level-2 <input>s and acts accordingly.
function tickOrUntickChildren(id) {
  var liId = "li-" + id;
  var checkboxId = "checkbox-" + id;

  if (document.getElementById(checkboxId).checked == true) {
    document.getElementById(checkboxId).checked = true;
    tickChildInputs(liId);
  } else {
    document.getElementById(checkboxId).checked = false;
    untickChildInputs(liId);
  }

  countInputs();
  createCheckboxesCookie();
  generateURL();
}

// *** 2. ***

// Returns the relevant parent level-1 <input> of a level-2 <input>
function getParentInput(checkbox) {
  var level2Element = document.getElementById(checkbox);
  var level1Li = level2Element.parentElement.parentElement.getElementsByTagName("input")[0];

  return level1Li;
}

// Returns the relevant level-2 sibling <input>s

function getSiblingInputs(checkboxId, liId) {
  var level2Element = document.getElementById(liId);
  var siblingsAndSelfLi = level2Element.parentElement.parentElement.getElementsByTagName("li");
  var siblings = [];

  for (var i = 0; i < siblingsAndSelfLi.length; i++) {
    if (siblingsAndSelfLi[i].id != liId) {
      siblings.push(siblingsAndSelfLi[i].getElementsByTagName("input")[0]);
    }
  }

  return siblings;
}

// Checks whether all siblings are ticked

function checkSiblings(siblings) {
  var output = false;

  for (var i = 0; i < siblings.length; i++) {
    if (siblings[i].checked == false) {
      output = true;
    }
  }

  return output;
}

// Uses the above functions. Determines whether to tick or untick the level-1 <input> whether clicking a level-2 <input>.

function tickOrUntickParent(id) {
  var liId = "li-" + id;
  var checkboxId = "checkbox-" + id;
  var checkboxElement = document.getElementById(checkboxId);

  var parent = getParentInput(liId);
  var siblings = getSiblingInputs(checkboxId, liId);
  var siblingsTicked = checkSiblings(siblings);

  if (siblingsTicked == false && checkboxElement.checked == true) {
    parent.checked = true;
  } else {
    parent.checked = false;
  }

  countInputs();
  createCheckboxesCookie();
  generateURL();
}
*/
// *** 3. ***

// Collects all the <input>s with the type 'checkbox' (as opposed to 'submit', for example) into an array.
function getCheckboxInputs() {
  var checkboxInputs = [];
  var inputs = document.getElementsByTagName("input");

  for (var i = 0; i < inputs.length; i++) {
    if (inputs[i].type == "checkbox") {
      checkboxInputs.push(inputs[i]);
    }
  }

  return checkboxInputs;
}

// Counts the inputs and updates the number value of selected inputs next to 'SELECTION' in the form.
function countInputs() {
  var checkBoxInputs = getCheckboxInputs();
  var tickedInputs = [];

  for (var i = 0; i < checkBoxInputs.length; i++) {
    if (checkBoxInputs[i].checked == true && checkBoxInputs[i].className == "pod-checkbox checkbox") {
      tickedInputs.push(checkBoxInputs[i]);
    }
  }

  var numberTicked = tickedInputs.length;
  document.getElementById("checkboxCount").innerHTML = numberTicked;
}

// Clears the selection.
function clearSelection() {
  var checkBoxInputs = getCheckboxInputs();

  for (var i = 0; i < checkBoxInputs.length; i++) {
    checkBoxInputs[i].checked = false;
  }

  countInputs();
  createCheckboxesCookie();
  generateURL();
}

// *** 4. ***
/*
// Adds the current page (if it is possible) to the selection for the PDF.
$('.add-to-pdf').on( "click", function() {
  var pageId = $(this).data("page-id");
  if($(this).hasClass('minus')) {
    return removeFromPDF(pageId);
  } else {
    return addToPDF(pageId);
  }
});
*/

$('.pod-checkbox').on( "click", function() {
  var pageId = $(this).data("page-id");
  if($(this).prop('checked')) {
    return addToPDF(pageId);
  } else {
    return removeFromPDF(pageId);
  }
});

function addToPDF(pageId) {
  countInputs();
  createCheckboxesCookie();

  return true;
}

function removeFromPDF(pageId) {
  countInputs();
  createCheckboxesCookie();

  return true;
}

// *** 5. ***

// Creates the cookie to remember the user's selection between pages.
function createCheckboxesCookie() {
  var checkboxesTickedArray = [];
  var checkboxInputs = getCheckboxInputs();

  for (var i = 0; i < checkboxInputs.length; i++) {
    if (checkboxInputs[i].checked == true) {
      checkboxesTickedArray.push(checkboxInputs[i]);
    }
  }

  var checkboxesTickedIdArray = [];
  for (var j = 0; j < checkboxesTickedArray.length; j++) {
    checkboxesTickedIdArray.push(checkboxesTickedArray[j].id);
  }
  var checkboxesTickedIdString = checkboxesTickedIdArray.join("&");

  var checkboxesCookie = "checkboxesCookie=" + checkboxesTickedIdString + ";path=/;";
  document.cookie = checkboxesCookie;
}

// Retrieves the checkboxesCookie and sets the user's previous selection choice.
function getCheckboxesCookie() {
  var cookie = document.cookie;
  var cookieArray = cookie.split(";");
  var cookieData = [];
  var regExp = /(\s)?checkboxesCookie=/gi;
  for (var i = 0; i < cookieArray.length; i++) {
    if (regExp.test(cookieArray[i])) {
      var cookieSplit = cookieArray[i].split("=");
      cookieData = cookieSplit[1].split("&");
    }
  }

  for (var j = 0; j < cookieData.length; j++) {
    var checkbox = document.getElementById(cookieData[j]);
    var split = cookieData[j].split('-');
    var pageId = split[split.length - 1];
    $('a[data-page-id='+pageId+']').addClass('minus');
    if (checkbox) {
      checkbox.checked = true;
    }
  }
}

// *** 6. ***
$('#pdfDownload').on( "click", function() {
  var podPath = $(this).data("pod-path");
  var checkBoxInputs = getCheckboxInputs();
  var tickedInputs = [];
  var nodes = '&nodes=';

  $('input.pod-checkbox').each(function(i){
    if ($(this)[0].checked == true) {
      if (nodes == '&nodes=') {
        nodes += $(this).data("page-id");
      } else {
        nodes += '-'
        nodes += $(this).data("page-id");
      }
    }
  });

  window.open(podPath+nodes);
});

$(document).ready(function() {
  if ($('#pdfDownload')) {
    getCheckboxesCookie();
  }
})